<template>
  <div id="singleModule" style="background: #fff;height:auto;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="desc">
        <p>WeMeet 由海南会多多信息技术有限公司及其关联公司（华珈投资、WeMeet Holding Limited）等企业组成，公司核心团队由数字科技和会展行业资深人士组成。公司于2018年成立并以“WeMeet”为数字会展核心品牌开展全球运营。 </p>

        <p> WeMeet聚焦数字会展赛道，汇聚数字科技和会展行业内的精英人士，围绕数字化、智能化、大数据应用解决方案，与头部科技企业，会展产业集团、城市会展或商旅主管机构战略合作，开发，建设和运营数字会展平台。为会展、城市、企业和商旅人士提供全天候、个性化的信息和科技服务。</p>

        <p> 通过与腾讯云、微信紧密合作，WeMeet目前已落地上海，杭州、新加坡，拉斯维加斯等国际会展商旅中心城市，并和多个国际会展主办方紧密合作，拥有多家长期生态链企业合作伙伴。</p>


        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/aboutUs/companyDoor.png" alt="公司大门" class="companyDoor">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'companyDesc',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '公司简介',
      autoPlay: false,
    }
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.desc {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  p {
    margin-bottom: 20px;
  }
  .companyDoor{
    width: 100%;
    height: 400px;
    margin-top: 50px;
  }
}
</style>